import { Link, useNavigate } from "react-router-dom"
import Logo from '../../assets/images/logos/logo.avif'
// import VoiceChatGIF from '../../assets/gif/login_gif.mp4'
// import VoiceChatGIF from '../../assets/gif/login_gif.gif'
import VoiceChatGIF from '../../assets/gif/new_gif.gif'
import { useContext, useEffect, useState } from "react"
import UserContext from "../../context/UserContext"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackGround from '../../assets/images/auth_bg.jpg'


const Login = () => {
  const context = useContext(UserContext)
  const { user, getUser, host } = context
  const navigate = useNavigate()
  useEffect(() => {
    if (localStorage.getItem('token')) {
      getUser()
      navigate('/dashboard')
    }
    else {
      // navigate('/')
    }
    // console.log(user, host)
  }, [])


  const [credentials, setCredentials] = useState({ email: '', password: '' })

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
    // console.log(credentials)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(credentials)

    try {
      
    
      
      const res = await fetch(`${host}/api/auth/login`, {
        method: 'POST',
        // mode:"no-cors",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
        
      },
    )
  

    const myres = await res.json();

    // console.log(myres)

    if (!myres.success) {
      toast(myres.error, {
        position: "top-right",
        type: "error",
        autoClose: 5000,

        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } 
    else {
      if (myres.authToken) {
        // if(true){
        toast('Logged in Successfully!\nRedirecting to dashboard.', {
          position: "top-right",
          type: "success",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        localStorage.setItem('token', myres.authToken)
        // localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjRiODI0MDMxNDZjYmQ3ZGIxODMyZTdiIn0sImlhdCI6MTcwODI3MzI1NX0.QyuI55Dw4kyTIrE8YCTHhz-xLKNtiGYwE5d8AUbroic')

        //   // setTimeout(()=>{
        //     //   getUser()
        //     //   // navigate('/starter')
        //     // },1000)

        setTimeout(() => {
          
          navigate('/dashboard/')
        }, 5000)

      }
      else {
        // console.log(myres)
        // console.log(myres.authToken)
        toast("Something went wrong", {
          position: "top-right",
          type: "error",
          autoClose: 5000,

          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        localStorage.clear()
      }
    }


  } catch (error) {
      if(window && !window.navigator.onLine){
        toast("You are not connected to Internet", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
  
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      else{
        toast(error, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
  
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
  }

  }


  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />

      {/* <div className="bg-black flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8" */}
      <div className=" flex w-full h-screen justify-around"
        // style={{ background: "linear-gradient(90deg, #6a3ab4 12%, #1dbefd 50%, #e6b46f 95%)" }}
        style={{backgroundImage: `url(${BackGround})`}}
      >

        {/* <div>
            <video src={VoiceChatGIF}  loop autoPlay muted />
          </div> */}

        <div className="relative flex-1 hidden items-center justify-center h-screen  lg:flex">
          <div className="relative z-10 w-full max-w-md" style={{ textAlign: "-webkit-center" }}>
          
            <img src={VoiceChatGIF} alt="KirstenAI" className="rounded-3xl " />
          </div>
          <div
            className="absolute inset-0 my-auto h-[500px]"
          // style={{
          //     background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)", filter: "blur(118px)"
          // }}
          >

          </div>
        </div>
        <div className=" flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">


          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              // className="mx-auto h-10 w-auto scale-150"
              className="mx-auto h-10 w-auto scale-[1.75]"
              src={Logo}
              alt="Your Company"
              style={{filter: "drop-shadow(1px 5px 2px black)"}}
            />
            <h2 className="mt-5 text-center text-2xl md:text-3xl font-bold leading-9 tracking-tight text-gray-100">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm relative">
            {/* <div className="absolute m-auto blur-[160px] max-w-sm h-40 top-12 inset-x-0"
              style={{ background: "linear-gradient(rgb(22 30 249) 100%, rgb(0 17 255 / 98%) 100%, rgba(50 178 255 / 74%) 100%)" }}></div> */}
            <form className="space-y-6 z-30 relative" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-100">
                  Email address
                </label>
                <div className="mt-2 flex">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={credentials.email}
                    required
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-100">
                    Password
                  </label>
                  <div className="text-sm">
                    <Link to="/forgot" className="font-semibold text-pink-600 hover:text-pink-500">
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <div className="mt-2 flex">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={credentials.password}
                    required
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mediumHover sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="flex">
                <button
                  type="submit"
                  // onClick={handleSubmit}
                  className="flex w-full justify-center rounded-md bg-medium px-3 py-1 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-mediumHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mediumHover"
                >
                  Sign in
                </button>
              </div>
            </form>

            <p className="mt-10 text-center text-sm text-gray-100 relative z-30">
              Not a member?{' '}
              <Link to="/signup" className="font-semibold leading-6 text-pink-600 hover:text-pink-500">
              Set up your account here
              </Link>
            </p>
          </div>

        </div>
      </div>
    </>
  )
}

export default Login
import { Link, useNavigate } from "react-router-dom"
import Logo from '../../assets/images/logos/logo.avif'
import BackGround from '../../assets/images/auth_bg.jpg'
import { useContext, useEffect, useState } from "react"
import UserContext from "../../context/UserContext"
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Forgot = () => {
    const context = useContext(UserContext)
    const {user, getUser, host} = context
    const navigate = useNavigate()
    useEffect(() => {
      if(localStorage.getItem('token')){  
        getUser()
        navigate('/dashboard')
      }
      else{
        // navigate('/')
      }
      // console.log(user, host)
    }, [])


    const [credentials, setCredentials] = useState({email:''})

    const handleChange = (e) => {
      setCredentials({...credentials, [e.target.name] : e.target.value})
      // console.log(credentials)
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      // console.log(credentials)

      try{
      
      const res = await fetch(`${host}/api/verify/forgot-password`, {
        method : 'POST',
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
        
      },
      )

      const myres = await res.json();

      // console.log(myres)

      if(!myres.success){
        toast(myres.error, {
          position: "top-right",
          type:"error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      } else{
      
        
          if(myres.msg){
            toast(myres.msg, {
              position: "top-right",
              type:"success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });

            // localStorage.setItem('token', myres.authToken)
            
            // setTimeout(()=>{
              //   getUser()
              //   // navigate('/starter')
              // },1000)
              
              // setTimeout(()=>{
              //   navigate('/dashboard')
              // },5000)
              
          }else{
            // console.log(myres)
            // console.log(myres.authToken)
            toast("Something went wrong", {
              position: "top-right",
              type:"error",
              autoClose: 5000,
    
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });

              localStorage.clear()
          }
      }


    } catch (error) {
      if(window && !window.navigator.onLine){
        toast("You are not connected to Internet", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
  
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      else{
        toast(error, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
  
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
  }

    }


    return (
      <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
        {/* Same as */}
        <ToastContainer />
      
        <div className="flex min-h-screen flex-1 flex-col justify-center px-6 lg:px-8"
        // style={{background:"linear-gradient(90deg, #6a3ab4 12%, #1dbefd 50%, #e6b46f 95%)"}
        style={{backgroundImage: `url(${BackGround})`
      , backgroundPosition:"center",
      backgroundSize:"contain"
      }
        }>
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-10 w-auto scale-150"
              src={Logo}
              alt="Your Company"
              style={{filter: "drop-shadow(1px 5px 2px black)"}}
            />
            <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-100">
              Reset your account password
            </h2>
          </div>
  
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm relative">
          {/* <div className="absolute m-auto blur-[160px] max-w-sm h-40 top-12 inset-x-0"
            style={{ background: "linear-gradient(rgb(22 30 249) 100%, rgb(0 17 255 / 98%) 100%, rgba(50 178 255 / 74%) 100%)" }}></div> */}
          
            
            <form className="space-y-6 z-30 relative">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-100">
                  Email address
                </label>
                <div className="mt-2 flex">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={credentials.email}
                    required
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mediumHover sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
  
              
  
              <div className="flex">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="flex w-full justify-center rounded-md bg-medium px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-mediumHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
                >
                  Get verification link
                </button>
              </div>
            </form>
  
            <p className="mt-10 text-center text-sm text-gray-100 z-30 relative">
              Already a member?{' '}
              <Link to="/login" className="font-semibold leading-6 text-pink-600 hover:text-pink-500">
                Login to your Account
              </Link>
            </p>
          </div>
        </div>
      </>
    )
  }

export default Forgot
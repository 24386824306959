import * as Dialog from "@radix-ui/react-dialog";

const Terms = ({ setIsChecked }) => {
    return (
        // <Dialog.Root>
        //   <Dialog.Trigger className="w-32 mx-auto py-2 ml-2 shadow-sm rounded-md bg-blue-600 text-white mt-4 flex items-center justify-center">
        //     Click me
        //   </Dialog.Trigger>
        <Dialog.Portal className="z-[5000]">
            <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black opacity-40" />
            <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4 z-[5000]">
                <div className="bg-white rounded-md shadow-lg z-[5000] overflow-y-scroll h-[80vh]">
                    <div className="flex items-center justify-between p-4 border-b">
                        <Dialog.Title className="text-lg font-medium text-gray-800 ">
                            Terms and Conditions + Privacy Policy

                        </Dialog.Title>
                        <Dialog.Close className="p-2 text-gray-400 rounded-md hover:bg-gray-100">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5 mx-auto"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </Dialog.Close>
                    </div>
                    <Dialog.Description className="space-y-2 p-4 mt-3 text-[15.5px] leading-relaxed text-gray-500 ">
                        <h3 className="font-semibold text-lg">Terms & Conditions for Conceivable AI</h3>

                        <p>
                            <b>1. Acceptance of Terms</b>
                            - By accessing and using Conceivable AI, you agree to be bound by these Terms and Conditions. If you do not agree, please do not use the app.
                        </p>
                        <p>
                            <b>2. Service Description</b>
                            - Conceivable AI is a wellness support tool provided by Conceivable Inc., headquartered in Austin, Texas. This app offers guidance to help identify factors affecting fertility. It does not diagnose, treat, or cure any conditions and should not replace professional medical advice.
                        </p>
                        <p>
                            <b>3. Intellectual Property</b>
                            - All content and technology associated with Conceivable AI are owned by Conceivable Inc. and are protected under applicable intellectual property laws. Unauthorized use is strictly prohibited.
                        </p>
                        <p>
                            <b>4. User Responsibilities</b>
                            - You must provide accurate information and use the app responsibly. Relying solely on this app without professional medical advice is discouraged. Always consult your physican on all of your health concerns.
                        </p>
                        <p>
                            <b>5. Privacy & Data Protection</b>
                            - To protect user privacy, Conceivable AI complies with HIPAA guidelines. We ensure secure data storage, strong access controls, and encrypted data transmission. Our full Privacy Policy details how personal information is handled.
                        </p>
                        <p>
                            <b>6. Limitation of Liability</b>
                            - Conceivable Inc. is not liable for any direct, indirect, incidental, or consequential damages resulting from the use of the app. We do not guarantee the app’s performance or the accuracy of AI-generated advice.
                        </p>
                        <p>
                            <b>7. Arbitration</b>
                            - Any disputes arising from the use of Conceivable AI will be resolved through binding arbitration in accordance with the laws of the State of Delaware.
                        </p>
                        <p>
                            <b>8. Modifications to Terms</b>
                            - Conceivable Inc. reserves the right to modify these Terms at any time. Changes will be communicated through email and effective immediately upon posting.
                        </p>
                        <p>
                            <b>9. Restrictions on Use</b>
                            As a user of Conceivable AI, you are prohibited from duplicating, copying, modifying, selling, re-selling, or exploiting any portion of the app, use of the app, or access to the app. You are also forbidden from reverse engineering or attempting to extract the software's source code, unless laws prohibit these restrictions or you have our written permission.
                        </p>
                        <p>
                            <b>10. Contact Us</b>
                            - For any questions or concerns regarding these Terms, please contact us at help@conceivable.com

                        </p>

                        <h3 className="font-semibold text-lg">Privacy Policy for Conceivable AI</h3>

                        <p>

                            <p>

                                <b>Introduction</b>
                                -This Privacy Policy outlines the measures taken by Conceivable Inc. to protect the privacy and security of your personal data when using the Conceivable AI app.
                            </p>
                            <p>
                                <b>

                                    Data Collection and Storage
                                </b>
                                <p>
                                    -User Accounts: Passwords are securely stored in a hashed format to ensure security.</p>
                                <p>-Data Storage: All user data is stored in a secure cloud database, which is protected by robust security measures.</p>
                            </p>
                            <p>
                                <b>

                                    Data Usage and Access
                                </b>
                                <p>

                                    -Restricted Access: Access to user data is highly restricted. Our AI chatbot is the only entity that has direct access to user data for operational purposes.
                                </p>
                                <p>

                                    -API Security: We use securely managed API keys to facilitate data transmission to OpenAI, which does not store or use this data for model training purposes.
                                </p>
                            </p>
                            <p>
                                <b>


                                    Data Transmission
                                </b>
                                <p>

                                    -Encryption: All data sent to and from our app is encrypted using HTTPS and SSL/TLS protocols to prevent unauthorized access during transmission.
                                </p>
                                <p>

                                    -Limited Display: User data is not displayed within the app interface to prevent data leakage in the event of unauthorized account access.
                                </p>
                            </p>
                            <p>
                                <b>


                                    Security Measures
                                </b>
                                <p>

                                    -Infrastructure: Our application codebase is hosted on a secure cloud infrastructure to further enhance security.
                                </p>
                                <p>

                                    -Access Control: Access to our cloud servers is limited to a few authorized personnel, with stringent controls to prevent data retrieval.
                                </p>
                                <p>

                                    -Two-Factor Authentication (2FA): Both our OpenAI account and cloud server deployments are protected with two-factor authentication, ensuring an additional layer of security.
                                </p>
                            </p>
                            <p>
                                <b>


                                    Compliance and Best Practices
                                </b>
                                -We conduct regular security assessments to ensure our protective measures meet high standards.
                                We have strict protocols for dealing with data breaches, including immediate action plans and notifications to affected parties.
                            </p>
                            <p>
                                <b>

                                    Your Rights
                                </b>
                                -You have the right to access, correct, or delete your personal data stored by us.
                                You may also withdraw consent for data use at any time, subject to legal or contractual restrictions.
                            </p>
                            <p>
                                <b>

                                    Contact Us
                                </b>
                                -For questions or concerns about our privacy practices, please contact us through the provided channels. We are committed to protecting your privacy and securing your personal data.
                            </p>
                        </p>


                    </Dialog.Description>
                    <div className="flex items-center gap-3 p-4 border-t">
                        <Dialog.Close asChild>
                            <button className="px-6 py-2 text-white bg-pink-600 rounded-md outline-none ring-offset-2 ring-pink-600 focus:ring-2 " onClick={() => setIsChecked(true)}>
                                Accept
                            </button>
                        </Dialog.Close>
                        <Dialog.Close asChild>
                            <button
                                className="px-6 py-2 text-gray-800 border rounded-md outline-none ring-offset-2 ring-pink-600 focus:ring-2"
                                aria-label="Close"
                            >
                                Cancel
                            </button>
                        </Dialog.Close>
                    </div>
                </div>
            </Dialog.Content>
        </Dialog.Portal>
        // </Dialog.Root>
    );
};


export default Terms;
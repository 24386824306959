import { Link, useNavigate } from "react-router-dom"
import Logo from '../../assets/images/logos/logo.avif'
import { useContext, useEffect, useState } from "react"
import UserContext from "../../context/UserContext"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import VoiceChatGIF from '../../assets/gif/new_gif.gif'
import * as Dialog from "@radix-ui/react-dialog";
import Terms from "./Terms";
import BackGround from '../../assets/images/auth_bg.jpg'




const Signup = () => {

  const context = useContext(UserContext)
  const {user, getUser, host} = context
  const navigate = useNavigate()
  useEffect(() => {
    if(localStorage.getItem('token')){  
      getUser()
      navigate('/dashboard')
    }
    else{
      // navigate('/')
    }
    // console.log(user, host)
  }, [])


  const [credentials, setCredentials] = useState({name: '', email:'', password: '', phoneNumber:''})

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    // e.preventDefault();
    console.log(isChecked)
    setIsChecked(!isChecked);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setCredentials({...credentials, [e.target.name] : e.target.value})
    // console.log(credentials)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(credentials)

    try{
    
    // const res = await fetch(`${host}/api/auth/create-user`, {
    if(isChecked){


  
    const res = await fetch(`${host}/api/verify/pre-verify`, {
      method : 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
      
    },
    )

    const myres = await res.json();

    // console.log(myres)

    if(!myres.success){
      toast(myres.error, {
        position: "top-right",
        type:"error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    } else{
        if(myres.msg){
          toast(myres.msg, {
            position: "top-right",
            type:"success",
            // autoClose: 5000,
            // hideProgressBar: false,
            // closeOnClick: true,
            // pauseOnHover: true,
            draggable: true,
            progress: 1,
            theme: "light",
            });

          // localStorage.setItem('token', myres.authToken)
          
          // setTimeout(()=>{
            //   getUser()
            //   // navigate('/starter')
            // },1000)
            
            // setTimeout(()=>{
            //   navigate('/dashboard')
            // },5000)
            
        }else{
          // console.log(myres)
          // console.log(myres.authToken)
          toast("Something went wrong", {
            position: "top-right",
            type:"error",
            // autoClose: 5000,
            // hideProgressBar: false,
            // closeOnClick: true,
            // pauseOnHover: true,
            draggable: true,
            progress: 1,
            theme: "light",
            });

            localStorage.clear()
        }
    }
    
    
  } else{
    toast(
      "Accept Terms and Conditions to create account.", {
      position: "top-right",
      type:"error",
      // autoClose: 5000,
      // hideProgressBar: false,
      // closeOnClick: true,
      // pauseOnHover: true,
      draggable: true,
      progress: 1,
      theme: "light",
      });
  }


} catch (error) {
  if(window && !window.navigator.onLine){
    toast("You are not connected to Internet", {
      position: "top-right",
      type: "error",
      autoClose: 5000,

      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  else{
    toast(error, {
      position: "top-right",
      type: "error",
      autoClose: 5000,

      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

  }

  
    return (
      <>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
        <ToastContainer />


        <div className=" flex w-full h-fit justify-around"
        style={{ 
          // background: "linear-gradient(90deg, #6a3ab4 12%, #1dbefd 50%, #e6b46f 95%)"
          backgroundImage: `url(${BackGround})`
        
        }}
      >
        

        <div className="relative flex-1 hidden items-center justify-center h-screen  lg:flex">
          <div className="relative z-10 w-full max-w-md" style={{ textAlign: "-webkit-center" }}>
            {/* <img src="https://floatui.com/logo-dark.svg" width={150} />
                    <div className=" mt-16 space-y-3">
                        <h3 className="text-white text-3xl font-bold">Start growing your business quickly</h3>
                        <p className="text-gray-300">
                            Create an account and get access to all features for 30-days, No credit card required.
                        </p>
                        <div className="flex items-center -space-x-2 overflow-hidden">
                            <img src="https://randomuser.me/api/portraits/women/79.jpg" className="w-10 h-10 rounded-full border-2 border-white" />
                            <img src="https://api.uifaces.co/our-content/donated/xZ4wg2Xj.jpg" className="w-10 h-10 rounded-full border-2 border-white" />
                            <img src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=a72ca28288878f8404a795f39642a46f" className="w-10 h-10 rounded-full border-2 border-white" />
                            <img src="https://randomuser.me/api/portraits/men/86.jpg" className="w-10 h-10 rounded-full border-2 border-white" />
                            <img src="https://images.unsplash.com/photo-1510227272981-87123e259b17?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=3759e09a5b9fbe53088b23c615b6312e" className="w-10 h-10 rounded-full border-2 border-white" />
                            <p className="text-sm text-gray-400 font-medium translate-x-5">
                                Join 5.000+ users
                            </p>
                        </div>
                    </div> */}

            {/* <video src={VoiceChatGIF}  loop autoPlay muted /> */}
            <img src={VoiceChatGIF} className="rounded-3xl " />
          </div>
          <div
            className="absolute inset-0 my-auto h-[500px]"
          // style={{
          //     background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)", filter: "blur(118px)"
          // }}
          >

          </div>
        </div>

        <div className=" flex flex-1 flex-col justify-center px-6 py-12 lg:px-8"
        // style={{background:"linear-gradient(90deg, #6a3ab4 12%, #1dbefd 50%, #e6b46f 95%)"}}
        >
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-10 w-auto scale-150"
              src={Logo}
              alt="Your Company"
              style={{filter: "drop-shadow(1px 5px 2px black)"}}
            />
            <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-50">
              Create your account
            </h2>
          </div>
  
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm relative">
          {/* <div className="absolute m-auto blur-[160px] max-w-sm h-40 top-12 inset-x-0"
                style={{ background: "linear-gradient(rgb(22 30 249) 100%, rgb(0 17 255 / 98%) 100%, rgba(50 178 255 / 74%) 100%)" }}>
              </div> */}
            <form className="space-y-6 z-30 relative"   onSubmit={handleSubmit}>
            <div>
                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-100">
                  Name
                </label>
                <div className="mt-2 flex">
                  <input
                    id="name"
                    name="name"
                    type="name"
                    autoComplete="name"
                    required
                    minLength={2}
                    value={credentials.name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    className="block w-full rounded-md border-0 py-1 px-1  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-100">
                  Email address
                </label>
                <div className="mt-2 flex">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={credentials.email}
                    onChange={handleChange}
                    placeholder="Your Email address"
                    className="block w-full rounded-md border-0 py-1 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
                
              <div>
                <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-100">
                    Phone Number
                </label>
                <div className="mt-2 flex">
                  <input
                    id="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    minLength={7}
                    autoComplete="phoneNumber"
                    pattern="[\+0-9 \-]+"
                    required
                    value={credentials.phoneNumber}
                    onChange={handleChange}
                    placeholder="Your Phone Number"
                    className="block w-full rounded-md border-0 py-1 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-100">
                    Password
                  </label>
                  {/* <div className="text-sm">
                    <a href="#" className="font-semibold text-blue-600 hover:text-blue-500">
                      Forgot password?
                    </a>
                  </div> */}
                </div>
                <div className="mt-2 flex">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    minLength={2}
                    value={credentials.password}
                    onChange={handleChange}
                    placeholder="Password"
                    className="block w-full rounded-md border-0 py-1 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between text-sm">
                        <div className="flex items-center gap-x-3">
                            <input 
                            type="checkbox" 
                            id="remember-me-checkbox" 
                            className="checkbox-item peer hidden" 
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            />
                            <label
                                htmlFor="remember-me-checkbox"
                                // className="relative flex w-5 h-5 bg-white peer-checked:bg-blue-600 rounded-md border ring-offset-2 ring-blue-600 duration-150 peer-active:ring cursor-pointer after:absolute after:inset-x-0 after:top-[3px] after:m-auto after:w-1 after:h-2 after:border-r-2 after:border-b-2 after:border-white after:rotate-45"
                                className="relative flex w-5 h-5 bg-white peer-checked:bg-medium rounded-md border ring-offset-2 ring-medium duration-150 peer-active:ring cursor-pointer after:absolute after:inset-x-0 after:top-[3px] after:m-auto after:w-1 after:h-2 after:border-r-2 after:border-b-2 after:border-white after:rotate-45"
                            >
                            </label>

                            <span className="text-white">I accept  {"  "}
                            <Dialog.Root className="z-[5000]">

                            <Dialog.Trigger className="text-pink-600 hover:text-pink-500 cursor-pointer"> Terms and Conditions</Dialog.Trigger>
                            <Terms setIsChecked={setIsChecked}/>
                            </Dialog.Root>
                            </span>
                        </div>
                        {/* <a href="javascript:void(0)" className="text-center text-blue-600 hover:text-blue-500">Forgot password?</a> */}
                    </div>
  
              <div className="flex">
                <button
                  type="submit"
                
                  className="flex w-full justify-center rounded-md bg-medium px-3 py-1 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-mediumHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-medium"
                >
                  Sign up
                </button>
              </div>
            </form>
  
            <p className="mt-10 text-center text-sm text-gray-50 relative z-30">
              Already a member?{' '}
              {/* <Link to="/login" className="font-semibold leading-6 text-blue-600 hover:text-blue-500"> */}
              <Link to="/login" className="font-semibold leading-6 text-pink-600 hover:text-pink-500">
                Login to your Account
              </Link>
            </p>
          </div>
        </div>


        </div>
      </>
    )
  }

  export default Signup